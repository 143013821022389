@import url("https://fonts.googleapis.com/css?family=Roboto:400,500");
/*! component's theme wise override definitions and variables */
/*! richtexteditor icons */
.e-rte-toolbar .e-alignments::before,
.e-rte-dropdown-popup .e-alignments::before {
  content: '\e33a';
}

.e-rte-toolbar .e-justify-left::before,
.e-rte-dropdown-popup .e-justify-left::before {
  content: '\e33a';
}

.e-rte-toolbar .e-justify-center::before,
.e-rte-dropdown-popup .e-justify-center::before {
  content: '\e35e';
}

.e-rte-toolbar .e-justify-right::before,
.e-rte-dropdown-popup .e-justify-right::before {
  content: '\e34d';
}

.e-rte-toolbar .e-justify-full::before,
.e-rte-dropdown-popup .e-justify-full::before {
  content: '\e334';
}

.e-rte-toolbar .e-font-name::before,
.e-rte-dropdown-popup .e-font-name::before {
  content: '\e35f';
}

.e-rte-toolbar .e-font-color::before,
.e-rte-dropdown-popup .e-font-color::before {
  content: '\e34c';
}

.e-rte-toolbar .e-background-color::before,
.e-rte-dropdown-popup .e-background-color::before {
  content: '\e35c';
}

.e-rte-toolbar .e-bold::before,
.e-rte-dropdown-popup .e-bold::before {
  content: '\e339';
}

.e-rte-toolbar .e-italic::before,
.e-rte-dropdown-popup .e-italic::before {
  content: '\e35a';
}

.e-rte-toolbar .e-underline::before,
.e-rte-dropdown-popup .e-underline::before {
  content: '\e343';
}

.e-rte-toolbar .e-strike-through::before,
.e-rte-dropdown-popup .e-strike-through::before {
  content: '\e332';
}

.e-rte-toolbar .e-clear-format::before,
.e-rte-dropdown-popup .e-clear-format::before {
  content: '\e331';
}

.e-rte-toolbar .e-clear-all::before,
.e-rte-dropdown-popup .e-clear-all::before {
  content: '\e340';
}

.e-rte-toolbar .e-cut::before,
.e-rte-dropdown-popup .e-cut::before {
  content: '\e33b';
}

.e-rte-toolbar .e-copy::before,
.e-rte-dropdown-popup .e-copy::before {
  content: '\e33d';
}

.e-rte-toolbar .e-paste::before,
.e-rte-dropdown-popup .e-paste::before {
  content: '\e355';
}

.e-rte-toolbar .e-unorder-list::before,
.e-rte-dropdown-popup .e-unorder-list::before {
  content: '\e344';
}

.e-rte-toolbar .e-order-list::before,
.e-rte-dropdown-popup .e-order-list::before {
  content: '\e346';
}

.e-rte-toolbar .e-indent::before,
.e-rte-dropdown-popup .e-indent::before {
  content: '\e33f';
}

.e-rte-toolbar .e-outdent::before,
.e-rte-dropdown-popup .e-outdent::before {
  content: '\e35d';
}

.e-rte-toolbar .e-undo::before,
.e-rte-dropdown-popup .e-undo::before {
  content: '\e341';
}

.e-rte-toolbar .e-redo::before,
.e-rte-dropdown-popup .e-redo::before {
  content: '\e354';
}

.e-rte-toolbar .e-super-script::before,
.e-rte-dropdown-popup .e-super-script::before {
  content: '\e352';
}

.e-rte-toolbar .e-sub-script::before,
.e-rte-dropdown-popup .e-sub-script::before {
  content: '\e357';
}

.e-rte-toolbar .e-create-link::before,
.e-rte-dropdown-popup .e-create-link::before {
  content: '\e34e';
}

.e-rte-toolbar .e-open-link::before,
.e-rte-dropdown-popup .e-open-link::before {
  content: '\e34a';
}

.e-rte-toolbar .e-edit-link::before,
.e-rte-dropdown-popup .e-edit-link::before {
  content: '\e338';
}

.e-rte-toolbar .e-remove-link::before,
.e-rte-dropdown-popup .e-remove-link::before {
  content: '\e348';
}

.e-rte-toolbar .e-image::before,
.e-rte-dropdown-popup .e-image::before {
  content: '\e335';
}

.e-rte-toolbar .e-replace::before,
.e-rte-dropdown-popup .e-replace::before {
  content: '\e359';
}

.e-rte-toolbar .e-align::before,
.e-rte-dropdown-popup .e-align::before {
  content: '\e33a';
}

.e-rte-toolbar .e-caption::before,
.e-rte-dropdown-popup .e-caption::before {
  content: '\e35b';
}

.e-rte-toolbar .e-remove::before,
.e-rte-dropdown-popup .e-remove::before {
  content: '\e33e';
}

.e-rte-toolbar .e-insert-link::before,
.e-rte-dropdown-popup .e-insert-link::before {
  content: '\e34e';
}

.e-rte-toolbar .e-display::before,
.e-rte-dropdown-popup .e-display::before {
  content: '\e358';
}

.e-rte-toolbar .e-alt-text::before,
.e-rte-dropdown-popup .e-alt-text::before {
  content: '\e33c';
}

.e-rte-toolbar .e-dimension::before,
.e-rte-dropdown-popup .e-dimension::before {
  content: '\e336';
}

.e-rte-toolbar .e-maximize::before,
.e-rte-dropdown-popup .e-maximize::before {
  content: '\e342';
}

.e-rte-toolbar .e-minimize::before,
.e-rte-dropdown-popup .e-minimize::before {
  content: '\e337';
}

.e-rte-toolbar .e-zoom-in::before,
.e-rte-dropdown-popup .e-zoom-in::before {
  content: '\e349';
}

.e-rte-toolbar .e-zoom-out::before,
.e-rte-dropdown-popup .e-zoom-out::before {
  content: '\e351';
}

.e-rte-toolbar .e-lower-case::before,
.e-rte-dropdown-popup .e-lower-case::before {
  content: '\e347';
}

.e-rte-toolbar .e-upper-case::before,
.e-rte-dropdown-popup .e-upper-case::before {
  content: '\e333';
}

.e-rte-toolbar .e-print::before,
.e-rte-dropdown-popup .e-print::before {
  content: '\e34b';
}

.e-rte-toolbar .e-formats::before,
.e-rte-dropdown-popup .e-formats::before {
  content: '\e353';
}

.e-rte-toolbar .e-source-code::before,
.e-rte-dropdown-popup .e-source-code::before {
  content: '\e350';
}

.e-rte-toolbar .e-preview::before,
.e-rte-dropdown-popup .e-preview::before {
  content: '\e345';
}

.e-rte-toolbar .e-view-side::before,
.e-rte-dropdown-popup .e-view-side::before {
  content: '\e34f';
}

.e-rte-toolbar .e-insert-code::before,
.e-rte-dropdown-popup .e-insert-code::before {
  content: '\e356';
}

.e-rte-toolbar .e-table-header::before,
.e-rte-dropdown-popup .e-table-header::before {
  content: '\eb03';
}

.e-rte-toolbar .e-table-remove::before,
.e-rte-dropdown-popup .e-table-remove::before {
  content: '\eb00';
}

.e-rte-toolbar .e-table-rows::before,
.e-rte-dropdown-popup .e-table-rows::before {
  content: '\eb02';
}

.e-rte-toolbar .e-table-columns::before,
.e-rte-dropdown-popup .e-table-columns::before {
  content: '\ea9e';
}

.e-rte-toolbar .e-table-cell-ver-align::before,
.e-rte-dropdown-popup .e-table-cell-ver-align::before {
  content: '\ea9b';
}

.e-rte-toolbar .e-table-edit-properties::before,
.e-rte-dropdown-popup .e-table-edit-properties::before {
  content: '\ea9a';
}

.e-rte-toolbar .e-create-table::before,
.e-rte-dropdown-popup .e-create-table::before {
  content: '\e705';
}

.e-rte-toolbar .e-align-bottom::before,
.e-rte-dropdown-popup .e-align-bottom::before {
  content: '\ea91';
}

.e-rte-toolbar .e-align-middle::before,
.e-rte-dropdown-popup .e-align-middle::before {
  content: '\ea94';
}

.e-rte-toolbar .e-align-top::before,
.e-rte-dropdown-popup .e-align-top::before {
  content: '\ea98';
}

.e-rte-toolbar .e-insert-column-left::before,
.e-rte-dropdown-popup .e-insert-column-left::before {
  content: '\ea97';
}

.e-rte-toolbar .e-insert-column-right::before,
.e-rte-dropdown-popup .e-insert-column-right::before {
  content: '\ea99';
}

.e-rte-toolbar .e-delete-column::before,
.e-rte-dropdown-popup .e-delete-column::before {
  content: '\ea95';
}

.e-rte-toolbar .e-insert-row-before::before,
.e-rte-dropdown-popup .e-insert-row-before::before {
  content: '\ea92';
}

.e-rte-toolbar .e-insert-row-after::before,
.e-rte-dropdown-popup .e-insert-row-after::before {
  content: '\ea93';
}

.e-rte-toolbar .e-delete-row::before,
.e-rte-dropdown-popup .e-delete-row::before {
  content: '\ea96';
}

.e-rte-toolbar .e-style::before,
.e-rte-dropdown-popup .e-style::before {
  content: '\ea9f';
}

.e-rte-toolbar .e-rte-file-manager::before,
.e-rte-dropdown-popup .e-rte-file-manager::before {
  content: '\e609';
}

.e-richtexteditor .e-south-east::before,
.e-richtexteditor .e-south-west::before {
  content: '\eb05';
}

/*! tab layout */
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child {
  margin-left: 3px;
}

.e-bigger .e-richtexteditor.e-rtl .e-rte-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-richtexteditor.e-bigger.e-rtl .e-rte-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child {
  margin-left: 0;
  margin-right: 3px;
}

.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-extended,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-extended,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended {
  padding-left: 3px;
}

.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon:not(.e-caret),
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon:not(.e-caret),
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon:not(.e-caret),
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon:not(.e-caret),
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon:not(.e-caret),
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon:not(.e-caret),
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon:not(.e-caret),
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon:not(.e-caret) {
  font-size: 18px;
}

.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-font-color .e-selected-color.e-icons::before,
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-background-color .e-selected-color.e-icons::before,
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-font-color .e-selected-color.e-icons::before,
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-background-color .e-selected-color.e-icons::before,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-font-color .e-selected-color.e-icons::before,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-background-color .e-selected-color.e-icons::before,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-font-color .e-selected-color.e-icons::before,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-background-color .e-selected-color.e-icons::before,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-font-color .e-selected-color.e-icons::before,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-background-color .e-selected-color.e-icons::before,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-font-color .e-selected-color.e-icons::before,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-background-color .e-selected-color.e-icons::before,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-font-color .e-selected-color.e-icons::before,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-background-color .e-selected-color.e-icons::before,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-font-color .e-selected-color.e-icons::before,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-background-color .e-selected-color.e-icons::before {
  top: -20px;
}

.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn,
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn {
  padding-left: 10px;
  padding-right: 6px;
}

.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn:hover,
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn:focus,
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn:active,
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn:hover,
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn:focus,
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn:active,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn:hover,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn:focus,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn:active,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn:hover,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn:focus,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn:active,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn:hover,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn:focus,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn:active,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn:hover,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn:focus,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn:active,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn:hover,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn:focus,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn:active,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn:hover,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn:focus,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn:active {
  padding-left: 10px;
  padding-right: 6px;
}

.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item.e-active .e-tbar-btn.e-btn,
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item.e-active .e-tbar-btn.e-btn,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item.e-active .e-tbar-btn.e-btn,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-active .e-tbar-btn.e-btn,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-items .e-toolbar-item.e-active .e-tbar-btn.e-btn,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-extended .e-toolbar-item.e-active .e-tbar-btn.e-btn,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item.e-active .e-tbar-btn.e-btn,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-active .e-tbar-btn.e-btn {
  padding: 0 6px;
}

.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-active .e-rte-color-content,
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-active .e-rte-color-content,
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-active .e-rte-color-content,
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-active .e-rte-color-content,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-active .e-rte-color-content,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-active .e-rte-color-content,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-active .e-rte-color-content,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-active .e-rte-color-content,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-active .e-rte-color-content,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-active .e-rte-color-content,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-active .e-rte-color-content,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-active .e-rte-color-content,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-active .e-rte-color-content,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-active .e-rte-color-content,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-active .e-rte-color-content,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-active .e-rte-color-content {
  line-height: 35px;
}

.e-bigger .e-richtexteditor .e-rte-toolbar .e-dropdown-btn .e-caret,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-dropdown-btn .e-caret,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-dropdown-btn .e-caret,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-dropdown-btn .e-caret {
  font-size: 12px;
}

.e-richtexteditor {
  color: #333;
  display: block;
  position: relative;
}

.e-richtexteditor textarea.e-content {
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  outline: 0;
  padding: 8px;
  resize: none;
  width: 100%;
}

.e-richtexteditor .e-rte-hidden {
  border: 0;
  display: block;
  height: 0;
  margin: 0;
  outline: 0;
  padding: 0;
  resize: none;
  visibility: hidden;
  width: 0;
}

.e-richtexteditor.e-disabled {
  pointer-events: none;
}

.e-richtexteditor.e-rte-full-screen {
  bottom: 0;
  height: 100% !important;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100% !important;
  z-index: 999;
}

.e-richtexteditor.e-rtl .e-rte-character-count {
  left: 0;
  padding-left: 30px;
  padding-right: unset;
  right: unset;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child {
  margin-left: 7px;
}

.e-richtexteditor .e-rte-toolbar .e-hor-nav.e-expended-nav {
  height: auto;
}

.e-richtexteditor.e-rtl .e-rte-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child {
  margin-left: 0;
  margin-right: 7px;
}

.e-richtexteditor .e-rte-toolbar.e-rte-tb-mobile .e-toolbar-multirow {
  margin-left: 0;
  margin-right: 0;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
}

.e-richtexteditor .e-rte-toolbar,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar {
  position: inherit;
  z-index: 101;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-extended,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended {
  margin-left: 0;
  padding-left: 7px;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-formats-tbar-btn > :first-child,
.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-formats-tbar-btn > :first-child,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-formats-tbar-btn > :first-child,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-formats-tbar-btn > :first-child {
  min-width: 0;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-font-name-tbar-btn > :first-child,
.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-font-name-tbar-btn > :first-child,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-font-name-tbar-btn > :first-child,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-font-name-tbar-btn > :first-child {
  min-width: 0;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-font-size-tbar-btn > :first-child,
.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-font-size-tbar-btn > :first-child,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-font-size-tbar-btn > :first-child,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-font-size-tbar-btn > :first-child {
  min-width: 0;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn,
.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-dropdown-btn,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-dropdown-btn {
  padding-left: 7px;
  padding-right: 2.5px;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn .e-rte-dropdown-btn-text,
.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-dropdown-btn .e-rte-dropdown-btn-text,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn .e-rte-dropdown-btn-text,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-dropdown-btn .e-rte-dropdown-btn-text {
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn:focus,
.e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn:active,
.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-dropdown-btn:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-dropdown-btn:focus,
.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-dropdown-btn:active,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn:hover,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn:focus,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn:active,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-dropdown-btn:hover,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-dropdown-btn:focus,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-dropdown-btn:active {
  padding-left: 7px;
  padding-right: 2.5px;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-font-color .e-selected-color.e-icons::before,
.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-font-color .e-selected-color.e-icons::before,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-font-color .e-selected-color.e-icons::before,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-font-color .e-selected-color.e-icons::before {
  font-size: 12px;
  position: relative;
  top: -18px;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-font-color.e-icons::before,
.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-font-color.e-icons::before,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-font-color.e-icons::before,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-font-color.e-icons::before {
  font-size: 13px;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-background-color.e-icons::before,
.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-background-color.e-icons::before,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-background-color.e-icons::before,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-background-color.e-icons::before {
  display: inline;
  font-size: 14px;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-active .e-rte-color-content,
.e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-active .e-rte-color-content,
.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-active .e-rte-color-content,
.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-active .e-rte-color-content,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-active .e-rte-color-content,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-active .e-rte-color-content,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-active .e-rte-color-content,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-active .e-rte-color-content {
  line-height: 25px;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon:not(.e-caret),
.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon:not(.e-caret),
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon:not(.e-caret),
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon:not(.e-caret) {
  font-size: 16px;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item.e-active .e-tbar-btn.e-btn,
.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item.e-active .e-tbar-btn.e-btn,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item.e-active .e-tbar-btn.e-btn,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-active .e-tbar-btn.e-btn {
  padding: 0 1.5px;
}

.e-richtexteditor .e-rte-toolbar.e-control[class*='e-toolbar'],
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar.e-control[class*='e-toolbar'] {
  box-sizing: border-box;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-extended.e-visible,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended.e-visible {
  display: block;
  visibility: hidden;
}

.e-richtexteditor .e-rte-toolbar.e-tbar-ios-fixed.e-rte-tb-fixed,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar.e-tbar-ios-fixed.e-rte-tb-fixed {
  top: 0;
  transform: translate3d(0, 0, 0);
}

.e-richtexteditor .e-rte-toolbar.e-rte-tb-fixed:not(.e-tbar-ios-fixed),
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar.e-rte-tb-fixed:not(.e-tbar-ios-fixed) {
  bottom: 0;
}

.e-richtexteditor .e-rte-toolbar.e-rte-tb-fixed,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar.e-rte-tb-fixed {
  display: none;
  left: 0;
  position: fixed;
  right: 0;
}

.e-richtexteditor .e-rte-toolbar.e-rte-tb-fixed.e-show,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar.e-rte-tb-fixed.e-show {
  display: block;
  z-index: 101;
}

.e-richtexteditor .e-rte-toolbar.e-rte-tb-float,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar.e-rte-tb-float {
  position: fixed;
  top: 0;
}

.e-richtexteditor .e-rte-toolbar.e-rte-tb-float.e-rte-tb-abs-float,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar.e-rte-tb-float.e-rte-tb-abs-float {
  position: absolute;
}

.e-richtexteditor .e-rte-toolbar .e-dropdown-btn .e-caret,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-dropdown-btn .e-caret {
  font-size: 12px;
}

.e-richtexteditor .rte-placeholder {
  color: #333;
  line-height: 16px;
  opacity: 00.54;
  overflow: hidden;
  position: absolute;
  text-align: start;
  top: 0;
  z-index: 1;
}

.e-richtexteditor .e-rte-content {
  position: relative;
  z-index: 1;
}

.e-richtexteditor .e-rte-content,
.e-richtexteditor .e-source-content {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  transition: 100ms ease-out;
  width: 100%;
}

.e-richtexteditor .e-rte-content .e-content,
.e-richtexteditor .e-source-content .e-content {
  background: unset;
  box-sizing: border-box;
  height: 100%;
  min-height: 100px;
  outline: 0 solid transparent;
  overflow-x: auto;
  padding: 16px;
  position: relative;
  text-align: inherit;
  z-index: 2;
}

@media screen and (min-width: 992px) {
  .e-richtexteditor .e-rte-content .e-content,
  .e-richtexteditor .e-source-content .e-content {
    font-size: 14px;
  }
}

.e-richtexteditor .e-rte-content .e-content p,
.e-richtexteditor .e-source-content .e-content p {
  margin: 0 0 10px;
  margin-bottom: 10px;
}

.e-richtexteditor .e-rte-content .e-content li,
.e-richtexteditor .e-source-content .e-content li {
  margin-bottom: 10px;
}

.e-richtexteditor .e-rte-content .e-content h1,
.e-richtexteditor .e-source-content .e-content h1 {
  font-size: 2.17em;
  font-weight: 400;
  line-height: 1;
  margin: 10px 0;
}

.e-richtexteditor .e-rte-content .e-content h2,
.e-richtexteditor .e-source-content .e-content h2 {
  font-size: 1.74em;
  font-weight: 400;
  margin: 10px 0;
}

.e-richtexteditor .e-rte-content .e-content h3,
.e-richtexteditor .e-source-content .e-content h3 {
  font-size: 1.31em;
  font-weight: 400;
  margin: 10px 0;
}

.e-richtexteditor .e-rte-content .e-content h4,
.e-richtexteditor .e-source-content .e-content h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
}

.e-richtexteditor .e-rte-content .e-content h5,
.e-richtexteditor .e-source-content .e-content h5 {
  font-size: 00.8em;
  font-weight: 400;
  margin: 0;
}

.e-richtexteditor .e-rte-content .e-content h6,
.e-richtexteditor .e-source-content .e-content h6 {
  font-size: 00.65em;
  font-weight: 400;
  margin: 0;
}

.e-richtexteditor .e-rte-content .e-content blockquote,
.e-richtexteditor .e-source-content .e-content blockquote {
  margin: 10px 0;
  margin-left: 0;
  padding-left: 5px;
}

.e-richtexteditor .e-rte-content .e-content pre,
.e-richtexteditor .e-source-content .e-content pre {
  background-color: inherit;
  border: 0;
  border-radius: 0;
  color: #333;
  font-size: inherit;
  line-height: inherit;
  margin: 0 0 10px;
  overflow: visible;
  padding: 0;
  white-space: pre-wrap;
  word-break: inherit;
  word-wrap: break-word;
}

.e-richtexteditor .e-rte-content .e-content strong,
.e-richtexteditor .e-rte-content .e-content b,
.e-richtexteditor .e-source-content .e-content strong,
.e-richtexteditor .e-source-content .e-content b {
  font-weight: 700;
}

.e-richtexteditor .e-rte-content .e-content a,
.e-richtexteditor .e-source-content .e-content a {
  text-decoration: none;
  -webkit-user-select: auto;
      -ms-user-select: auto;
          user-select: auto;
}

.e-richtexteditor .e-rte-content .e-content a:hover,
.e-richtexteditor .e-source-content .e-content a:hover {
  text-decoration: underline;
}

.e-richtexteditor .e-rte-content .e-content p:last-child,
.e-richtexteditor .e-rte-content .e-content pre:last-child,
.e-richtexteditor .e-rte-content .e-content blockquote:last-child,
.e-richtexteditor .e-source-content .e-content p:last-child,
.e-richtexteditor .e-source-content .e-content pre:last-child,
.e-richtexteditor .e-source-content .e-content blockquote:last-child {
  margin-bottom: 0;
}

.e-richtexteditor .e-rte-content .e-content h3 + h4,
.e-richtexteditor .e-rte-content .e-content h4 + h5,
.e-richtexteditor .e-rte-content .e-content h5 + h6,
.e-richtexteditor .e-source-content .e-content h3 + h4,
.e-richtexteditor .e-source-content .e-content h4 + h5,
.e-richtexteditor .e-source-content .e-content h5 + h6 {
  margin-top: 00.6em;
}

.e-richtexteditor .e-rte-content .e-content ul:last-child,
.e-richtexteditor .e-source-content .e-content ul:last-child {
  margin-bottom: 0;
}

.e-richtexteditor .e-rte-character-count {
  bottom: 0;
  color: #000;
  font-family: 'Times New Roman', Georgia, Serif;
  font-size: 14px;
  margin-right: 30px;
  opacity: 00.54;
  padding-bottom: 2px;
  position: absolute;
  right: 0;
  z-index: 100;
}

.e-richtexteditor .e-rte-character-count.e-warning {
  color: #ffb74d;
  opacity: unset;
}

.e-richtexteditor .e-rte-character-count.e-error {
  color: #c62828;
  opacity: unset;
}

.e-richtexteditor .e-rte-srctextarea {
  background-color: transparent;
  border: 0;
  color: #333;
  display: block;
  height: 100%;
  line-height: 22px;
  min-height: 200px;
  overflow: auto;
  padding: 16px;
  resize: none;
  transition: 100ms ease-out;
  width: 100%;
}

.e-richtexteditor img::selection {
  background: transparent;
  color: transparent;
}

.e-richtexteditor .e-resize-handle {
  height: 15px;
  position: absolute;
  width: 15px;
}

.e-richtexteditor .e-resize-handle.e-south-east {
  bottom: 0;
  cursor: nwse-resize;
  right: 0;
  z-index: 100;
}

.e-richtexteditor.e-rte-tb-expand.e-rte-fixed-tb-expand {
  transition: none;
}

.e-rte-linkcontent .e-rte-label {
  padding-top: 20px;
}

.e-rte-label label {
  font-weight: normal;
}

.e-rte-linkcontent .e-rte-label:first-child {
  padding-top: 0;
}

.e-bigger .e-rte-dropdown-popup ul {
  min-width: 84px;
}

@media screen and (max-width: 768px) {
  .e-rte-dropdown-popup.e-rte-inline-dropdown ul {
    padding: 7px 0;
  }
  .e-rte-dropdown-popup.e-rte-inline-dropdown ul li.e-item {
    height: 34px;
    line-height: 34px;
    padding: 0 6.5px;
  }
  .e-rte-dropdown-popup.e-rte-inline-dropdown ul .e-item .e-menu-icon {
    float: none;
  }
}

@media screen and (max-width: 768px) {
  .e-rte-dropdown-popup.e-rte-inline-dropdown.e-rte-dropdown-icons.e-dropdown-popup {
    max-height: 280px;
  }
  .e-rte-dropdown-popup.e-rte-inline-dropdown.e-rte-dropdown-icons.e-dropdown-popup ul {
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
}

.e-rte-dropdown-popup ul {
  min-width: 82px;
}

.e-rte-dropdown-popup ul .e-item .e-menu-icon {
  margin: 0 6px;
  width: auto;
}

.e-rte-dropdown-popup ul .e-item.e-h1 {
  font-size: 2em;
  font-weight: bold;
  height: 40px;
  line-height: 40px;
}

.e-rte-dropdown-popup ul .e-item.e-h2 {
  font-size: 1.5em;
  font-weight: bold;
  height: 40px;
  line-height: 40px;
}

.e-rte-dropdown-popup ul .e-item.e-h3 {
  font-size: 1.16em;
  font-weight: bold;
}

.e-rte-dropdown-popup ul .e-item.e-h4 {
  font-size: 1em;
  font-weight: bold;
}

.e-rte-dropdown-popup ul .e-item.e-h5 {
  font-size: .83em;
  font-weight: bold;
}

.e-rte-dropdown-popup ul .e-item.e-h6 {
  font-size: .7em;
  font-weight: bold;
}

.e-rte-dropdown-popup ul .e-item.e-segoe-ui {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.e-rte-dropdown-popup ul .e-item.e-arial {
  font-family: Arial, Helvetica, sans-serif;
}

.e-rte-dropdown-popup ul .e-item.e-courier-new {
  font-family: Courier New, Courier, monospace;
}

.e-rte-dropdown-popup ul .e-item.e-georgia {
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.e-rte-dropdown-popup ul .e-item.e-helvetica-neue {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.e-rte-dropdown-popup ul .e-item.e-impact {
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

.e-rte-dropdown-popup ul .e-item.e-lucida-console {
  font-family: Lucida Console, Monaco, monospace;
}

.e-rte-dropdown-popup ul .e-item.e-tahoma {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.e-rte-dropdown-popup ul .e-item.e-times-new-roman {
  font-family: 'Times New Roman', Times, serif;
}

.e-rte-dropdown-popup ul .e-item.e-trebuchet-ms {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.e-rte-dropdown-popup ul .e-item.e-verdana {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.e-rte-dropdown-popup ul .e-item.e-roboto {
  font-family: 'Roboto', 'Segoe UI', 'GeezaPro', 'DejaVu Serif', sans-serif;
}

.e-rte-dropdown-popup.e-rte-dropdown-icons ul {
  min-width: auto;
}

@media screen and (max-width: 768px) {
  .e-rte-dropdown-popup.e-rte-dropdown-icons ul {
    min-width: 30px;
  }
}

.e-rte-image.e-imgbreak {
  border: 0;
  cursor: pointer;
  display: block;
  float: none;
  margin: 5px auto;
  max-width: 100%;
  position: relative;
}

.e-rte-image {
  border: 0;
  cursor: pointer;
  display: block;
  float: none;
  margin: auto;
  max-width: 100%;
  position: relative;
}

.e-rte-image.e-imginline {
  display: inline-block;
  float: none;
  margin-left: 5px;
  margin-right: 5px;
  max-width: calc(100% - (2 * 5px));
  vertical-align: bottom;
}

.e-rte-image.e-imgcenter {
  cursor: pointer;
  display: block;
  float: none;
  margin: 5px auto;
  max-width: 100%;
  position: relative;
}

.e-rte-image.e-imgleft {
  float: left;
  margin: 0 auto;
  margin-right: 5px;
  text-align: left;
}

.e-rte-image.e-imgright {
  float: right;
  margin: 0 auto;
  margin-left: 5px;
  text-align: right;
}

.e-rte-img-popup .e-item {
  height: 106px;
  width: 300px;
}

.e-rte-img-caption {
  display: inline-block;
  margin: 5px auto;
  max-width: 100%;
  position: relative;
}

.e-rte-img-caption.e-caption-inline {
  display: inline-block;
  margin: 5px auto;
  margin-left: 5px;
  margin-right: 5px;
  max-width: calc(100% - (2 * 5px));
  position: relative;
  text-align: center;
  vertical-align: bottom;
}

.e-rte-img-caption.e-imgcenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.e-rte-img-caption.e-imgright {
  display: block;
  margin-left: auto;
  margin-right: 0;
}

.e-rte-img-caption.e-imgleft {
  display: block;
  margin-left: 0;
  margin-right: auto;
}

.e-rte-img-caption .e-rte-image.e-imgright {
  float: none;
  margin-left: auto;
  margin-right: 0;
}

.e-rte-img-caption .e-rte-image.e-imgleft {
  float: none;
  margin: 0;
}

.e-img-caption.e-rte-img-caption.e-imgbreak {
  display: block;
}

.e-rte-table {
  border-collapse: collapse;
  empty-cells: show;
}

.e-rte-table td,
.e-rte-table th {
  border: 1px solid #bdbdbd;
  height: 20px;
  min-width: 20px;
  padding: 2px 5px;
  vertical-align: middle;
}

.e-rte-table.e-dashed-border td,
.e-rte-table.e-dashed-border th {
  border-style: dashed;
}

.e-rte-img-caption .e-img-inner {
  box-sizing: border-box;
  display: block;
  font-size: 16px;
  font-weight: initial;
  margin: auto;
  opacity: .9;
  position: relative;
  text-align: center;
  width: 100%;
}

.e-rte-img-caption .e-img-wrap {
  display: inline-block;
  margin: auto;
  padding: 0;
  width: 100%;
}

.e-rte-img-dialog .e-rte-label {
  padding-top: 20px;
}

.e-rte-img-dialog .e-rte-label:first-child {
  padding-top: 0;
}

.e-rte-table-resize.e-row-resize,
.e-rte-table-resize.e-column-resize {
  background-color: transparent;
  background-repeat: repeat;
  bottom: 0;
  cursor: col-resize;
  height: 1px;
  overflow: visible;
  position: absolute;
  width: 1px;
}

.e-rte-table-resize.e-row-resize {
  cursor: row-resize;
  height: 1px;
}

.e-richtexteditor .e-linkheader {
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
  font-size: 15px;
  opacity: .87;
  padding-bottom: 20px;
  padding-top: 20px;
}

.e-richtexteditor .e-img-uploadwrap.e-droparea .e-upload .e-upload-files .e-file-delete-btn.e-icons,
.e-richtexteditor .e-img-uploadwrap.e-droparea .e-upload .e-upload-files .e-file-abort-btn.e-icons {
  margin-top: 0;
}

.e-richtexteditor .e-img-uploadwrap.e-droparea .e-upload .e-upload-files .e-upload-file-list {
  border-bottom: 0 solid transparent;
  min-height: initial;
}

.e-richtexteditor .e-img-uploadwrap.e-droparea .e-upload .e-upload-files .e-upload-file-list .e-file-container {
  margin-left: 0;
}

.e-richtexteditor .e-img-uploadwrap.e-droparea .e-upload .e-upload-files {
  border-top: 0 solid transparent;
}

.e-richtexteditor .e-img-uploadwrap.e-droparea .e-file-select-wrap {
  display: none;
}

.e-richtexteditor .e-img-uploadwrap.e-droparea .e-upload {
  border: 0 solid transparent;
  float: none;
}

.e-richtexteditor .e-dialog .e-img-uploadwrap.e-droparea .e-browsebtn {
  display: block;
  height: 36px;
  margin: -50px auto;
  padding: 0 18px;
}

.e-richtexteditor .e-dialog.e-device.e-dlg-modal .e-img-uploadwrap.e-droparea .e-browsebtn {
  display: none;
}

.e-richtexteditor .e-dialog .e-img-uploadwrap.e-droparea {
  line-height: 10;
  min-height: 50px;
  position: relative;
}

.e-richtexteditor .e-dialog .e-img-uploadwrap.e-droparea .e-rte-upload-text {
  display: inline-block;
  line-height: normal;
}

.e-richtexteditor .e-dialog.e-device.e-dlg-modal .e-img-uploadwrap.e-droparea {
  line-height: 4;
}

.e-richtexteditor .e-rte-inline-dropdown ul {
  max-height: 200px;
  overflow-y: auto;
}

.e-richtexteditor .e-rte-dropdown-popup.e-rte-dropdown-items ul {
  max-height: 280px;
  overflow-y: auto;
}

.e-richtexteditor .e-bigger .e-rte-dropdown-popup.e-rte-dropdown-items ul {
  max-height: 360px;
  overflow-y: auto;
}

.e-richtexteditor .e-rte-inline-dropdown.e-rte-backgroundcolor-dropdown,
.e-richtexteditor .e-rte-inline-dropdown.e-rte-fontcolor-dropdown {
  line-height: 0;
}

.e-richtexteditor .e-rte-inline-dropdown .e-rte-color-content .e-rte-elements {
  border-bottom-style: solid;
  border-bottom-width: 3px;
  line-height: 1;
  padding-bottom: 1px;
}

.e-richtexteditor .e-rte-table-popup.e-popup.e-popup-open {
  border-radius: 0;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.26);
  font-size: 14px;
  font-weight: normal;
  min-width: 120px;
  overflow: hidden;
  padding: 10px;
}

.e-richtexteditor .e-rte-table-popup.e-popup-open .e-rte-tablecell {
  border: 1px solid #bdbdbd;
  display: inline-block;
  height: 14px;
  margin: 1px;
  overflow: hidden;
  vertical-align: top;
  width: 14px;
}

.e-richtexteditor .e-rte-table-popup.e-popup-open .e-rte-table-row {
  height: 16px;
}

.e-richtexteditor .e-rte-table-popup.e-popup-open .e-insert-table-btn {
  width: 100%;
}

.e-richtexteditor .e-rte-edit-table .e-rte-field {
  padding-top: 20px;
}

.e-richtexteditor .e-rte-edit-table .e-rte-field:first-child {
  padding-top: 0;
}

.e-richtexteditor .e-rte-content .e-content table td.e-cell-select,
.e-richtexteditor .e-rte-content .e-content table th.e-cell-select {
  border: 1px double #0478d7;
  height: 24.67px;
}

.e-richtexteditor span.e-table-box {
  cursor: nwse-resize;
  display: block;
  height: 10px;
  position: absolute;
  width: 10px;
}

.e-richtexteditor span.e-table-box.e-rmob {
  height: 14px;
  width: 14px;
}

.e-richtexteditor .e-upload .e-upload-files .e-upload-file-list .e-file-container {
  margin-right: 50px;
}

.e-richtexteditor .e-rte-upload-popup {
  width: 250px;
}

.e-richtexteditor .e-rte-dialog-upload .e-upload-files {
  border-top: 0;
}

.e-richtexteditor .e-rte-dialog-upload .e-upload-files .e-upload-file-list {
  border-bottom: 0;
}

.e-rte-backgroundcolor-colorpicker .e-color-palette.e-container .e-custom-palette .e-palette,
.e-rte-fontcolor-colorpicker .e-color-palette.e-container .e-custom-palette .e-palette {
  padding: 0;
}

.e-rte-backgroundcolor-colorpicker .e-color-palette.e-container .e-switch-ctrl-btn,
.e-rte-fontcolor-colorpicker .e-color-palette.e-container .e-switch-ctrl-btn {
  padding: 5px;
}

.e-bigger .e-rte-backgroundcolor-colorpicker .e-color-palette.e-container .e-switch-ctrl-btn, .e-bigger
.e-rte-fontcolor-colorpicker .e-color-palette.e-container .e-switch-ctrl-btn {
  padding: 5px;
}

.e-rte-quick-popup {
  border-radius: 2px;
  overflow: hidden;
}

.e-rte-quick-popup .e-rte-quick-toolbar {
  border-radius: 2px;
  min-height: 42px;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos).e-toolbar-multirow {
  white-space: nowrap;
}

.e-rte-quick-popup .e-rte-quick-toolbar.e-remove-white-space .e-toolbar-items:not(.e-tbar-pos).e-toolbar-multirow {
  white-space: nowrap;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) {
  border-radius: 2px;
  margin: 0 6px;
  min-height: 42px;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item.e-rte-horizontal-separator,
.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item.e-separator.e-multirow-separator.e-rte-horizontal-separator {
  display: block;
  height: 1px;
  margin: 0;
  min-height: 1px;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item {
  margin: 0 6px;
  min-height: 42px;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child, .e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0 6px;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item .e-tbar-btn:not(.e-rte-dropdown-btn) {
  line-height: 20px;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item .e-tbar-btn:not(.e-rte-dropdown-btn) .e-icons {
  min-width: 24px;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item .e-btn,
.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item .e-btn:hover {
  min-height: 26px;
  min-width: 27px;
  padding: 0;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-tbar-btn .e-icons.e-btn-icon:not(.e-caret) {
  font-size: 16px;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-background-color.e-icons::before {
  display: inline;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-rte-backgroundcolor-dropdown .e-btn-icon.e-caret {
  font-size: 10px;
}

.e-rte-quick-popup .e-rte-dropdown-btn.e-tbar-btn {
  line-height: 24px;
  margin: 4px 0;
  padding: 1px 0;
}

.e-rte-quick-popup .e-rte-dropdown-btn.e-tbar-btn .e-rte-dropdown-btn-text {
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.e-rte-quick-popup .e-rte-dropdown-btn.e-tbar-btn .e-icons:not(.e-caret) {
  font-size: 14px;
  margin-left: -3px;
}

.e-rte-quick-popup .e-rte-dropdown-btn.e-tbar-btn .e-caret {
  font-size: 10px;
  width: 12px;
}

.e-rte-quick-popup.e-hide {
  display: block;
  visibility: hidden;
}

.e-bigger .e-rte-quick-popup .e-rte-quick-toolbar {
  min-height: 48px;
}

.e-bigger .e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) {
  margin: 0 6px;
  min-height: 48px;
}

.e-bigger .e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:not(.e-separator) {
  margin: 0 6px;
  min-height: 48px;
  min-width: 36px;
  padding: 0;
}

.e-bigger .e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item .e-tbar-btn:not(.e-rte-dropdown-btn) {
  line-height: normal;
}

.e-bigger .e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item .e-btn,
.e-bigger .e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item .e-btn:hover {
  min-height: 34px;
  min-width: 39px;
  padding: 0;
}

.e-bigger .e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-bigger .e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0 6px;
}

.e-bigger .e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item .e-tbar-btn.e-btn.e-control {
  height: 34px;
  line-height: normal;
  margin: 0;
  min-height: 34px;
  min-width: 39px;
}

.e-bigger .e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon:not(.e-caret) {
  font-size: 18px;
}

.e-bigger .e-rte-quick-popup .e-rte-dropdown-btn.e-tbar-btn {
  line-height: 33px;
  margin: 4px 0;
  padding: 0;
}

.e-bigger .e-rte-quick-popup .e-rte-dropdown-btn.e-tbar-btn .e-icons:not(.e-caret) {
  font-size: 16px;
}

.e-bigger .e-rte-quick-popup .e-rte-dropdown-btn.e-tbar-btn .e-caret {
  font-size: 12px;
  width: 20px;
}

.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  padding-right: 0;
}

.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover, .e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active, .e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active, .e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover {
  padding-left: 0;
  padding-right: 0;
}

.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover {
  padding-bottom: 0;
  padding-top: 0;
}

.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content {
  position: relative;
  vertical-align: middle;
  width: 30px;
}

.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements {
  border-bottom-style: solid;
  border-bottom-width: 3px;
  line-height: 1;
  padding-bottom: 1px;
}

.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after {
  content: '';
  height: 100%;
  position: absolute;
  right: 0;
  width: 0;
}

.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  line-height: 1;
  min-width: 18px;
  text-align: center;
  width: 18px;
}

.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon {
  min-width: 20px;
  width: 20px;
}

.e-popup-modal.e-popup.e-popup-open {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.e-rte-modal-popup.e-popup-container.e-center {
  -ms-flex-pack: center;
      justify-content: center;
}

.e-rte-modal-popup.e-popup-container {
  -ms-flex-align: center;
      align-items: center;
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
}

.e-popup-overlay {
  height: 100%;
  left: 0;
  opacity: .5;
  position: absolute;
  top: 0;
  width: 100%;
}

.e-bigger .e-rte-table-popup.e-popup-open .e-rte-tablecell {
  height: 16px;
  width: 16px;
}

.e-bigger .e-rte-table-popup.e-popup-open .e-rte-table-row {
  height: 18px;
}

.e-table-rhelper {
  cursor: col-resize;
  opacity: .87;
  position: absolute;
}

.e-table-rhelper.e-column-helper {
  width: 1px;
}

.e-table-rhelper.e-row-helper {
  height: 1px;
}

.e-reicon::before {
  border-bottom: 6px solid transparent;
  border-right: 6px solid;
  border-top: 6px solid transparent;
  content: '';
  display: block;
  height: 0;
  position: absolute;
  right: 4px;
  top: 4px;
  width: 20px;
}

.e-reicon::after {
  border-bottom: 6px solid transparent;
  border-left: 6px solid;
  border-top: 6px solid transparent;
  content: '';
  display: block;
  height: 0;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 20px;
  z-index: 3;
}

.e-row-helper.e-reicon::after {
  top: 10px;
  transform: rotate(90deg);
}

.e-row-helper.e-reicon::before {
  left: 4px;
  top: -20px;
  transform: rotate(90deg);
}

.e-rte-overflow {
  overflow: hidden;
}

.e-rte-dialog-minheight {
  min-height: 296px;
}

.e-content-placeholder.e-richtexteditor.e-placeholder-richtexteditor {
  background-size: 700px 190px;
  min-height: 190px;
}

.e-bigger .e-content-placeholder.e-richtexteditor.e-placeholder-richtexteditor,
.e-bigger.e-content-placeholder.e-richtexteditor.e-placeholder-richtexteditor {
  background-size: 700px 190px;
  min-height: 190px;
}

.e-rte-table.e-rte-table-border {
  border: 1px solid #bdbdbd;
  border-collapse: separate;
}

.e-rte-img-dialog.e-dialog.e-device.e-dlg-modal .e-img-uploadwrap.e-droparea {
  line-height: 10;
  min-height: 50px;
  position: relative;
}

.e-rte-img-dialog.e-dialog.e-device.e-dlg-modal .e-img-uploadwrap.e-droparea .e-browsebtn {
  display: block;
  height: 36px;
  margin: -50px auto;
  padding: 0 18px;
}

.e-rte-img-dialog.e-dialog.e-device.e-dlg-modal .e-img-uploadwrap.e-droparea .e-upload {
  border: 0 solid transparent;
  float: none;
}

.e-rte-img-dialog.e-dialog.e-device.e-dlg-modal .e-img-uploadwrap.e-droparea .e-file-select-wrap {
  display: none;
}

.e-rte-img-dialog.e-dialog.e-device.e-dlg-modal .e-linkheader {
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
  font-size: 15px;
  opacity: .87;
  padding-bottom: 20px;
  padding-top: 20px;
}

.e-rte-file-manager-dialog .e-rte-label {
  padding-bottom: 8px;
  padding-top: 15px;
}

.e-rte-file-manager-dialog .e-rte-label label {
  font-size: 15px;
  opacity: .87;
}

.e-rte-upload-popup.e-dialog .e-file-select-wrap {
  display: none;
}

.e-rte-upload-popup.e-dialog .e-dlg-content {
  overflow: hidden;
  padding: 0;
}

.e-hide.e-rte-quick-popup-hide {
  border: 0;
  height: 0;
  position: absolute;
  width: 0;
}

.e-rte-popup-hide {
  display: none;
}

.e-rte-hide-visible {
  visibility: hidden;
}

.e-rte-table-popup.e-dialog .e-dlg-content {
  padding: 0;
}

.e-richtexteditor .e-rte-table-popup.e-popup-open.e-dialog .e-rte-tablecell {
  margin: 0;
}

/*! tab layout */
.e-richtexteditor .e-rte-toolbar {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 0;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item.e-active .e-tbar-btn,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item.e-active .e-tbar-btn:focus {
  background: rgba(0, 0, 0, 0.12);
  border: 0;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item.e-active .e-tbar-btn .e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item.e-active .e-tbar-btn:focus .e-icons {
  color: #000;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-icons {
  color: #000;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-dropdown-btn.e-active {
  background: rgba(184, 184, 184, 0.9584);
  border-color: inherit;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-dropdown-btn.e-active .e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-dropdown-btn.e-active .e-rte-dropdown-btn-text {
  color: #000;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown:hover .e-rte-color-content::after,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown:hover .e-rte-color-content::after {
  background: transparent;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown:active:hover .e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown:active:hover .e-icons {
  color: #000;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown:active:hover .e-caret:active.e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown:active:hover .e-rte-color-content:active .e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown:active:hover .e-caret:active.e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown:active:hover .e-rte-color-content:active .e-icons {
  color: #000;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown:hover {
  background: rgba(0, 0, 0, 0.04);
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown:hover .e-rte-color-content:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown:hover .e-rte-color-content:hover {
  background: rgba(0, 0, 0, 0.12);
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown:hover .e-rte-color-content:focus,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown:hover .e-rte-color-content:focus {
  background: rgba(0, 0, 0, 0.24);
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown:hover .e-rte-color-content:active,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown:hover .e-rte-color-content:active {
  background: rgba(0, 0, 0, 0.36);
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown:hover .e-caret:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown:hover .e-caret:hover {
  background: rgba(0, 0, 0, 0.12);
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown:hover .e-caret:focus,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown:hover .e-caret:focus {
  background: rgba(0, 0, 0, 0.24);
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown:hover .e-caret:active,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown:hover .e-caret:active {
  background: rgba(0, 0, 0, 0.36);
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-active,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown.e-active {
  background: rgba(0, 0, 0, 0.36);
  border: 0;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-active .e-rte-color-content::after,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown.e-active .e-rte-color-content::after {
  background: transparent;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-active:hover .e-caret:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-active:hover .e-rte-color-content:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown.e-active:hover .e-caret:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown.e-active:hover .e-rte-color-content:hover {
  background: transparent;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-active .e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-active:active .e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown.e-active .e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown.e-active:active .e-icons {
  color: #000;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-icons {
  color: #000;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item.e-active .e-tbar-btn .e-icons {
  color: #000;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item.e-overlay .e-tbar-btn .e-icons {
  color: rgba(0, 0, 0, 0.26);
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-dropdown-btn.e-active {
  background: rgba(184, 184, 184, 0.9584);
}

.e-richtexteditor .e-rte-toolbar.e-rte-tb-float {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.e-richtexteditor .e-rte-toolbar.e-tb-static {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.e-richtexteditor .e-toolbar-wrapper + .e-rte-toolbar:not(.e-rte-tb-float) {
  border: 0;
}

.e-richtexteditor .e-rte-content,
.e-richtexteditor .e-source-content {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  color: #333;
}

.e-richtexteditor .e-rte-content .e-content blockquote,
.e-richtexteditor .e-source-content .e-content blockquote {
  border-left: solid 2px #333;
}

.e-richtexteditor .e-rte-content .e-content .e-img-focus:not(.e-resize),
.e-richtexteditor .e-source-content .e-content .e-img-focus:not(.e-resize) {
  border: solid 2px #4a90e2;
}

.e-richtexteditor .e-rte-content .e-content a,
.e-richtexteditor .e-source-content .e-content a {
  color: #2e2ef1;
}

.e-richtexteditor.e-rte-resize {
  background: #fff;
}

.e-richtexteditor .e-input.e-rte-linkurl.e-error,
.e-richtexteditor .e-input.e-img-link.e-error {
  color: #f00;
}

.e-richtexteditor.e-rte-full-screen {
  background: #fff;
}

.e-richtexteditor.e-rte-inline .e-rte-content {
  border: 0;
}

.e-richtexteditor:not(.e-rte-tb-expand) {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.e-richtexteditor:not(.e-rte-tb-expand) .e-rte-content {
  border: 0;
  border-bottom: 0;
  border-top: 0;
}

.e-richtexteditor.e-rte-tb-expand {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.e-richtexteditor.e-rte-tb-expand .e-rte-toolbar {
  border: 0;
}

.e-richtexteditor.e-rte-tb-expand .e-rte-toolbar.e-extended-toolbar,
.e-richtexteditor.e-rte-tb-expand .e-rte-toolbar .e-toolbar-extended {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.e-richtexteditor.e-rte-tb-expand .e-rte-toolbar .e-toolbar-extended {
  border: 0;
  border-bottom: 0;
}

.e-richtexteditor.e-rte-tb-expand .e-rte-toolbar.e-expand-open + .e-rte-content {
  border-top: 0;
}

.e-richtexteditor.e-rte-tb-expand .e-rte-toolbar.e-rte-tb-float {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.e-richtexteditor.e-rte-tb-expand .e-rte-toolbar.e-rte-tb-float.e-expand-open {
  border-bottom: 1px solid #fafafa;
}

.e-richtexteditor.e-rte-tb-expand .e-rte-toolbar.e-rte-tb-float.e-expand-open .e-toolbar-extended {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.e-richtexteditor.e-rte-tb-expand .e-rte-content,
.e-richtexteditor.e-rte-tb-expand .e-source-content {
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.e-richtexteditor.e-rte-tb-expand .e-toolbar-extended.e-popup-open {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.e-richtexteditor.e-rte-tb-expand.e-rte-resize .e-rte-content,
.e-richtexteditor.e-rte-tb-expand.e-rte-resize .e-source-content {
  border-bottom: 0;
}

.e-rte-quick-popup {
  border: 0;
  border-radius: 2px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.e-rte-quick-popup .e-rte-quick-toolbar {
  background: #fff;
  border: 0;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items {
  background: #fff;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items .e-separator {
  border: 0.5px solid rgba(0, 0, 0, 0.05);
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items .e-tbar-btn {
  background: #fff;
  border: 0;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items .e-tbar-btn:hover {
  background: rgba(0, 0, 0, 0.12);
  border-color: #fff;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items .e-tbar-btn:focus {
  background: rgba(0, 0, 0, 0.12);
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items .e-tbar-btn:active {
  background: rgba(0, 0, 0, 0.12);
  border-color: #fff;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items .e-tbar-btn:active .e-icons {
  color: #000;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items .e-toolbar-item.e-active .e-tbar-btn,
.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items .e-toolbar-item.e-active .e-tbar-btn:focus {
  background: rgba(0, 0, 0, 0.12);
  border: 0;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items .e-toolbar-item.e-active .e-tbar-btn .e-icons,
.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items .e-toolbar-item.e-active .e-tbar-btn:focus .e-icons {
  color: #000;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items .e-icons {
  color: #000;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-icons {
  color: #000;
}

.e-rte-quick-popup .e-rte-dropdown-btn {
  background: #fff;
  border-color: #fff;
}

.e-rte-quick-popup .e-rte-dropdown-btn:focus, .e-rte-quick-popup .e-rte-dropdown-btn:hover {
  background: rgba(0, 0, 0, 0.12);
}

.e-rte-quick-popup .e-rte-dropdown-btn:hover {
  border-color: #fff;
}

.e-dialog .e-img-uploadwrap .e-droptext {
  border: dashed 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.38);
  display: block;
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
  font-size: 14px;
  height: 185px;
  margin: 0 auto;
  text-align: center;
  width: 300px;
}

.e-dialog.e-device.e-dlg-modal .e-img-uploadwrap .e-droptext {
  margin: 0 auto;
  width: 250px;
}

.e-linkheader {
  color: rgba(0, 0, 0, 0.87);
}

.e-rte-img-popup.e-popup {
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  color: #000;
  padding: 10px;
}

.e-rte-content .e-content img:not(.e-resize) {
  z-index: 1000;
}

span.e-rte-imageboxmark {
  background: #4a90e2;
  border: 1px solid #fff;
  display: block;
  height: 10px;
  position: absolute;
  width: 10px;
  z-index: 1000;
}

.e-mob-rte span.e-rte-imageboxmark {
  background: #fff;
  border: 1px solid #4a90e2;
  border-radius: 15px;
  height: 20px;
  width: 20px;
}

.e-mob-rte.e-mob-span span.e-rte-imageboxmark {
  background: #4a90e2;
  border: 1px solid #fff;
}

.e-rte-content .e-content img.e-resize {
  z-index: 1000;
}

*.e-disabled {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

*.e-disabled .e-rte-toolbar .e-toolbar-item {
  cursor: default;
}

*.e-disabled .e-rte-toolbar .e-toolbar-item .e-tbar-btn.e-icon-btn:hover, *.e-disabled .e-rte-toolbar .e-toolbar-item .e-tbar-btn.e-icon-btn:active, *.e-disabled .e-rte-toolbar .e-toolbar-item .e-tbar-btn.e-icon-btn:focus,
*.e-disabled .e-rte-toolbar .e-toolbar-item .e-dropdown-btn.e-btn:hover,
*.e-disabled .e-rte-toolbar .e-toolbar-item .e-dropdown-btn.e-btn:active,
*.e-disabled .e-rte-toolbar .e-toolbar-item .e-dropdown-btn.e-btn:focus,
*.e-disabled .e-rte-toolbar .e-toolbar-item .e-colorpicker-wrapper .e-btn:hover,
*.e-disabled .e-rte-toolbar .e-toolbar-item .e-colorpicker-wrapper .e-btn:active,
*.e-disabled .e-rte-toolbar .e-toolbar-item .e-colorpicker-wrapper .e-btn:focus {
  background: transparent;
  cursor: default;
}

.e-dropdown-popup ul .e-item.e-active {
  background-color: #bdbdbd;
  color: rgba(0, 0, 0, 0.87);
}

.e-popup-overlay {
  background-color: #383838;
}

.e-rte-toolbar.e-extended-toolbar .e-toolbar-item.e-overlay .e-dropdown-btn {
  color: rgba(0, 0, 0, 0.26);
}

.e-rte-table-popup.e-popup.e-popup-open {
  background-color: #fff;
  color: #000;
}

.e-rte-table-popup .e-span-border {
  border: 1px solid rgba(0, 0, 0, 0.12);
  display: block;
  margin-bottom: 8px;
  margin-top: 12px;
}

.e-rte-table-popup .e-rte-popup-header {
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
  font-size: 14px;
  text-align: center;
}

.e-rte-table-popup.e-popup-open .e-rte-tablecell.e-default {
  background-color: #fff;
}

.e-rte-table-popup.e-popup-open .e-rte-tablecell.e-active {
  background-color: rgba(227, 22, 91, 0.2);
  border: 1px solid #e3165b;
}

.e-rte-table.e-alternate-border tbody tr:nth-child(2n) {
  background-color: #f5f5f5;
}

.e-rte-table th {
  background-color: #e0e0e0;
}

.e-rte-table-popup.e-popup.e-popup-open {
  background-color: #fff;
  color: #000;
}

span.e-table-box {
  background-color: #fff;
  border: 1px solid #bdbdbd;
}

span.e-table-box.e-rbox-select {
  background-color: #bdbdbd;
  border: 1px solid #bdbdbd;
}

.e-table-rhelper {
  background-color: #e3165b;
}

.e-rte-dialog-upload.e-upload.e-lib.e-keyboard {
  background: #fff;
}

.e-rte-inline-popup .e-rte-quick-toolbar.e-rte-toolbar .e-toolbar-items .e-toolbar-item.e-active .e-tbar-btn .e-icons,
.e-rte-inline-popup .e-rte-quick-toolbar.e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn:active .e-icons,
.e-rte-inline-popup .e-rte-quick-toolbar.e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn:focus .e-icons,
.e-rte-inline-popup .e-rte-quick-toolbar.e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn:hover .e-icons {
  color: #000;
}

.e-richtexteditor.e-rte-tb-expand .e-rte-content-border {
  border-bottom: 0;
}
